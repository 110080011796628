.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

.tamano {
  width: 10%;
}
hola{
  border: 3px;
  border-style: solid;
  border-color: rgb(255, 33, 3);
}
.separador{
  margin-top: 100px;
}
.fondo{
  background-color: '#222c31';
  border: 3px;
  border-style: solid;
  border-color: rgb(255, 33, 3);
}

input{
  color: 'white'
}
